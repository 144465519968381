import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

const gridStyle = { minHeight: 320, borderRadius: 4 };

const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
  pageText: "Страница ",
  ofText: " из ",
  perPageText: "Элементов на странице",
  showingText: "Показано ",
  clearAll: "Очистить все",
  clear: "Очистить",
  showFilteringRow: "Показать фильтры",
  hideFilteringRow: "Скрыть фильтры",
  enable: "Включить",
  disable: "Выключить",
  sortAsc: "По возрастанию",
  sortDesc: "По убыванию",
  unsort: "Без сортировки",
  group: "Group",
  ungroup: "Ungroup",
  lockStart: "Lock start",
  lockEnd: "Lock end",
  unlock: "Unlock",
  columns: "Колонки",
  contains: "Содержит",
  startsWith: "Начинается с",
  endsWith: "Заканчивается на",
  notContains: "Не содержит",
  inlist: "В списке",
  notinlist: "Не в списке",
  neq: "Не эквивалентно",
  inrange: "В диапазоне",
  notinrange: "Вне диапазона",
  eq: "Эквивалентно",
  notEmpty: "Не пусто",
  empty: "Пусто",
  lt: "Меньше чем",
  lte: "Меньше чем или равно",
  gt: "Больше чем",
  gte: "Больше чем или равно",
  before: "До",
  beforeOrOn: "До или на",
  afterOrOn: "После или на",
  after: "После",
  start: "Начало",
  end: "Конец",
  dragHeaderToGroup: "Переместите заголовок для группировки",
  "calendar.todayButtonText": "Сегодня",
  "calendar.clearButtonText": "Очистить",
  "calendar.okButtonText": "OK",
  "calendar.cancelButtonText": "Отмена",
});

const ItemsTable = (props) => {
  const { items, setItem, fields, setGridRef, idProperty, loading, allProducts, allTaxes, typePriceId } = props;

  const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
    autoHide: false,
    alwaysShowTrack: true,
  });

  const onEditComplete = useCallback(
    ({ value, columnId, rowIndex, cellProps }) => {
      const data = items;
      let validValue = value;

      if (cellProps.name === "nomenclatureRecording" || cellProps.name === "nomenclatureInvoice") {
        if (value !== null) {
          data[rowIndex]["price"] = allProducts.find((val) => val.id === value)?.minBuyPrice;
        } else {
          data[rowIndex]["price"] = 0;
        }
      }

      if (cellProps.name === "nomenclatureInvoice") {
        if (value !== null) {
          if (allProducts.find((val) => val.id === value)?.taxId) data[rowIndex]["taxInvoice"] = allProducts.find((val) => val.id === value).taxId;
        }
      }

      if (cellProps.name === "nomenclatureOrder") {
        if (value !== null) {
          if (allProducts.find((val) => val.id === value)?.taxId) data[rowIndex]["taxOrder"] = allProducts.find((val) => val.id === value).taxId;
        }
      }

      if (cellProps.name === "nomenclatureComing") {
        if (value !== null) {
          data[rowIndex]["price"] = allProducts.find((val) => val.nomenclatureId === value)?.price;
        } else {
          data[rowIndex]["price"] = 0;
        }
      }

      if (cellProps.name === "writeDownItemId") {
        if (value !== null) {
          let currentProduct = allProducts.find((val) => val.id === value);
          if(currentProduct)
          {
            data[rowIndex]["isManufactured"] = currentProduct.isManufactured;
            data[rowIndex]["productId"] = value;
            data[rowIndex]["nomenclatureWriteDown"] = currentProduct.nomenclatureId;
          }
        }
      }

      if (cellProps.name === "moveItemId") {
        if (value !== null) {
          let currentProduct = allProducts.find((val) => val.id === value);
          if(currentProduct)
          {
            data[rowIndex]["isManufactured"] = currentProduct.isManufactured;
            data[rowIndex]["productId"] = value;
            data[rowIndex]["nomenclatureMove"] = currentProduct.nomenclatureId;
          }
        }
      }

      if (cellProps.name === "nomenclatureOrder") {
        if (value !== null) {
          console.log(typePriceId);
          let currentProduct = allProducts.find((val) => val.id === value);
          let currentPrice;

          if (currentProduct && currentProduct?.typePrices) {
            currentPrice = currentProduct.typePrices.find((val) => val.namePrice.id === typePriceId);
            if (currentPrice) {
              currentPrice = currentPrice.valueCurrency;
            } else {
              currentPrice = currentProduct.salePrice;
            }
          } else {
            currentPrice = 0;
          }

          data[rowIndex]["clientPrice"] = currentPrice;
        } else {
          data[rowIndex]["clientPrice"] = 0;
        }
      }

      if (cellProps.name === "price" || cellProps.name === "count") {
        validValue = Math.abs(value);
      }

      if (cellProps.name === "storageWriteDown" && items[rowIndex].storageWriteDown !== value) {
        data[rowIndex]["nomenclatureWriteDown"] = null;
        data[rowIndex]["writeDownItemId"] = null;
      }

      if (cellProps.name === "nomenclatureShipment" && items[rowIndex].nomenclatureShipment !== value) {
        data[rowIndex]["storageShipment"] = null;
      }
      if(cellProps.name === "storageOutId"){
        if(data[rowIndex]["storageOutId"] !== null){
          data[rowIndex]["storageInId"] = null;
        }
      }

      data[rowIndex][columnId] = validValue;

      setItem(items.map((elem) => (elem.id === cellProps.data.id ? cellProps.data : elem)));
    },
    [allProducts, items]
  );

  return (
    <ReactDataGrid
      {...props}
      handle={setGridRef}
      idProperty={idProperty ? idProperty : "id"}
      style={props.minimalHeight ? {...gridStyle, minHeight: props.minimalHeight } : gridStyle}
      i18n={i18n}
      columns={fields}
      dataSource={items}
      loading={false}
      loadingText="Пожалуйста, подождите, получаем список товаров"
      emptyText={<h3 style={{ fontWeight: "bold", textAlign: "center" }}>В документе пока нет строк</h3>}
      onEditComplete={onEditComplete}
      editable={true}
      enableFiltering={false}
      showColumnMenuLockOptions={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuFilterOptions={false}
      showFilteringRow={false}
      showColumnMenuToolOnHover={false}
      //nativeScroll={true}
      //scrollProps={scrollProps}
      enableKeyboardNavigation={false}
      sortable={false}
    />
  );
};

ItemsTable.propTypes = {
  items: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  setItem: PropTypes.func.isRequired,
};

export default React.memo(ItemsTable);
