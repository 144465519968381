import {manufactureConstants} from "../../constants";

const defaultState = {
    items: [],
    item: {},
    loadingItems: false,
    loadingItem: false,
    fetchedItems: false,
    fetchedItem: false,
    error: null
};

function normalData(arr) {
    if (!Array.isArray(arr)) return []

    return arr.map((elem, index) => Object.assign(elem, {
        index: (index + 1),
        ordersString: Array.isArray(elem.ordersId) && elem.ordersId.length > 0 ? elem.ordersId : [],
        whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,
    }))
}

function normalItem(elem) {
    elem["items"] = elem["products"];
    console.log(elem["items"]);
    delete elem["products"];
    return elem;
}

export const manufactureReducer = function (state = defaultState, action) {
    switch (action.type) {
        case manufactureConstants.GETALL_REQUEST_MANUFACTURE:
            return {
                ...state,
                item: {},
                loadingItems: true,
                loadingItem: false,
                fetchedItems: false,
                fetchedItem: false,
                error: null
            };
        case manufactureConstants.GETALL_SUCCESS_MANUFACTURE:
            return {
                ...state,
                items: normalData(action.response),
                loadingItems: false,
                fetchedItems: true,
            };
        case manufactureConstants.GETALL_FAILURE_MANUFACTURE:
            return {
                ...state,
                loadingItems: false,
                fetchedItems: false,
                error: action.error,
            };

        case manufactureConstants.GETBYID_REQUEST_MANUFACTURE:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case manufactureConstants.GETBYID_SUCCESS_MANUFACTURE:
            return {
                ...state,
                items: state.items.map((elem) => {
                        if(elem.id !== action.response.id) {
                            return elem;
                        }
                        elem.viewStatus = action.response?.viewStatus;
                        return elem;
                }),
                item: normalItem(action.response),
                loadingItem: false,
                fetchedItem: true,
            };
        case manufactureConstants.GETBYID_FAILURE_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case manufactureConstants.CREATE_REQUEST_MANUFACTURE:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case manufactureConstants.CREATE_SUCCESS_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                item: normalItem(action.response),
                items: normalData(state.items.concat(action.response))
            };
        case manufactureConstants.CREATE_FAILURE_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case manufactureConstants.UPDATE_REQUEST_MANUFACTURE:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case manufactureConstants.UPDATE_SUCCESS_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: true,
                item: action.response,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
                    return elem
                }))
            };
        case manufactureConstants.UPDATE_FAILURE_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case manufactureConstants.ARCHIVE_REQUEST_MANUFACTURE:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null,
            };
        case manufactureConstants.ARCHIVE_SUCCESS_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive })
                    return elem
                }))
            };
        case manufactureConstants.ARCHIVE_FAILURE_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                error: action.error,
            };
        case manufactureConstants.CHANGE_STATUS_REQUEST_MANUFACTURE:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null,
            };
        case manufactureConstants.CHANGE_STATUS_SUCCESS_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                item: normalItem(action.response),
                items: normalData(state.items.concat(action.response))
            };
        case manufactureConstants.CHANGE_STATUS_FAILURE_MANUFACTURE:
            return {
                ...state,
                loadingItem: false,
                error: action.error,
            };
        default:
            return state;
    }
}